import { Position } from "geojson";

export const createPolygon = (rings: Position) => {
  return {
    type: "polygon",
    rings,
    spatialReference: { wkid: 4326 },
  };
};
export const createPolyline = (paths: Position) => {
  return {
    type: "polyline",
    paths,
    spatialReference: { wkid: 4326 },
  };
};