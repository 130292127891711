export type Color = [number, number, number]; // a tuple representing the RGB values

export type LegendColor = {
  value: number;
  color: Color;
  label: string;
  legend?: string;
};
export type ShadeColors = LegendColor[];
// TODO: the data provided is oddly reversed, so value 1 is actually meant to be value 0
export const shadeColors: ShadeColors = [
  { value: 1, color: [215, 25, 28], label: "0", legend: "0 mins" },
  { value: 0.75, color: [253, 174, 97], label: "15", legend: "15 mins" },
  { value: 0.5, color: [255, 255, 170], label: "30", legend: "30 mins" },
  { value: 0.25, color: [171, 217, 233], label: "45", legend: "45 mins" },
  { value: 0, color: [44, 123, 182], label: "60", legend: "60 mins" },
];

type UvColors = LegendColor[];
export const uvColors: UvColors = [
  { value: 0.99, color: [105, 191, 78], label: "1" },
  { value: 1.99, color: [50, 135, 24], label: "2" },
  { value: 2.99, color: [255, 204, 77], label: "3" },
  { value: 3.99, color: [255, 150, 0], label: "4" },
  { value: 4.99, color: [253, 113, 8], label: "5" },
  { value: 5.99, color: [249, 91, 51], label: "6" },
  { value: 6.99, color: [255, 53, 4], label: "7" },
  { value: 7.99, color: [237, 21, 75], label: "8" },
  { value: 8.99, color: [193, 19, 60], label: "9" },
  { value: 9.99, color: [128, 10, 40], label: "10" },
  { value: 10.99, color: [120, 70, 148], label: "11" },
  { value: 11.99, color: [90, 24, 128], label: "12" },
  { value: 12.99, color: [44, 0, 69], label: "13" },
  { value: Infinity, color: [0, 0, 0], label: ">13" },
];

export const getShadeColor = (value: number): Color => {
  const item = shadeColors.find((f) => f.value === value);
  return item?.color || [128, 128, 128];
};
export const getUvColor = (value: number): Color => {
  const breakpoint = uvColors.find((bp) => value <= bp.value);
  return breakpoint?.color ?? [128, 128, 128];
};
