import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./features/apiSlice";
import configReducer from "./features/configSlice";
import locationReducer from "./features/locationSlice";
import analysisReducer from "./features/analysisSlice";
import treesettingsSlice from "./features/treesettingsSlice";
import layersSlice from './features/layerSlice'

const store = configureStore({
  reducer: {
    config: configReducer,
    location: locationReducer,
    analysis: analysisReducer,
    treesettings: treesettingsSlice,
    layers: layersSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
