export const colors = {
  "sapphireblue": "#09549F",
  "darkblue": "#05325F",
  "lightblue": "#0085B3",
  "lightgreen": "#6BBE27",
  "darkgreen": "#008635",
  "goldenyellow": "#FFE500",
  "traditionalmaroon": "#771E32",
  "modernmaroon": "#A70240",
  lightgrey: "#F5F5F5",
  grey: "#E8E8E8",
  yellow: "#FFEF61",
};
export const box = {
  boxShadow: "0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
  border: "none",
  borderRadius: "4px",
};
