import "./index.css";
import { useCallback, useState, ReactNode } from "react";

import ToggleButton from "./ToggleButton";

type ContainerProps<T> = {
  children: ReactNode;
};
export default function Container<T>({ children }: ContainerProps<T>) {
  const [toggle, setToggle] = useState(true);
  const handleToggle = useCallback(() => {
    setToggle(!toggle)
  }, [toggle]);
  return (
    <>
      <ToggleButton toggle={toggle} handleToggle={handleToggle} />
      <div className={`side-panel ${!toggle? 'off-screen' : ''}`}>{children}</div>
    </>
  );
}
