import { useState, useCallback } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import RangeSlider from "react-bootstrap-range-slider";

import RowContainer from "../../../common/RowContainer";
import { useAppDispatch } from "../../../redux/hooks";
import { Values, Value } from "../../../types";

const colStyle = {
  paddingRight: 0,
  paddingLeft: 0,
};
const labelStyle = {
  fontSize: 14,
  color: "#636363",
};

const getDefault = (data: Values, selected: Value | null) => {
  if (!data || !selected) return 0;
  return data.findIndex((obj) => obj.id === selected.id);
};
const getLabel = (data: Values, index: number) => {
  if (!data || index === null) return "";
  return data[index] && data[index].name;
};

type Props = {
  action: any;
  id: string;
  name: string;
  values: Values;
  selected: Value | null;
};
export default function TimeSlider({
  action,
  id,
  name,
  values,
  selected,
}: Props) {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<number>(getDefault(values, selected));
  const onAfterChange = useCallback(
    (e: any) => {
      const item = values[e.target.value];
      if (item !== null) dispatch(action(item));
    },
    [values]
  );

  return (
    <RowContainer>
      <Col style={{ ...colStyle }} xs md={{ span: 3 }}>
        <Form.Label style={{ ...labelStyle }}>{name}</Form.Label>
      </Col>
      <Col style={{ ...colStyle }} xs md={{ span: 6 }}>
        {selected?.name && (
          <RangeSlider
            tooltip="off"
            value={value}
            variant="dark"
            min={0}
            max={values.length - 1}
            step={1}
            onAfterChange={onAfterChange}
            onChange={(e) => setValue(+e.target.value)}
          />
        )}
      </Col>
      <Col style={{ ...colStyle }} xs md={{ span: 3 }}>
        <Form.Label style={{ marginLeft: "0.25rem" }}>
          {getLabel(values, value)}
        </Form.Label>
      </Col>
    </RowContainer>
  );
}
