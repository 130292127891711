import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Value } from "../../types";

type TimeOfDay = Value | null;
type AnalysisType = Value | null;

interface AnalysisState {
  [key: string]: any;
  timeofday: TimeOfDay;
  analysistype: AnalysisType;
}
const initialState = {
  timeofday: null,
  analysistype: null,
} as AnalysisState;

const analysisSlice = createSlice({
  name: "analysis",
  initialState,
  reducers: {
    updateAnalysisDefault: (state, action: PayloadAction<AnalysisState>) => {
      state.timeofday = action.payload.timeofday;
      state.analysistype = action.payload.analysistype;
    },
    updateTimeOfDay: (state, action: PayloadAction<TimeOfDay>) => {
      state.timeofday = action.payload;
    },
    updateAnalysisType: (state, action: PayloadAction<AnalysisType>) => {
      state.analysistype = action.payload;
    },
  },
});

export const selectAnalysis = (state: RootState) => state.analysis;
export const selectTimeOfDay = (state: RootState) => state.analysis.timeofday;
export const selectAnalysisType = (state: RootState) =>
  state.analysis.analysistype;
// exports
export default analysisSlice.reducer;
export const { updateTimeOfDay, updateAnalysisType, updateAnalysisDefault } =
  analysisSlice.actions;
