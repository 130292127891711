import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface LayersState {
  [key: string]: any;
  treelayer: boolean;
  pathlayer: boolean;
}
const initialState = {
  treelayer: true,
  pathlayer: true,
} as LayersState;

const layerSlice = createSlice({
  name: "layers",
  initialState,
  reducers: {
    updateTreeLayer: (state, action: PayloadAction<boolean>) => {
      state.treelayer = action.payload;
    },
    updatePathLayer: (state, action: PayloadAction<boolean>) => {
      state.pathlayer = action.payload;
    },
  },
});

export const selectLayers = (state: RootState) => state.layers;
export const selectTreeLayer = (state: RootState) => state.layers.treelayer;
export const selectPathLayer = (state: RootState) => state.layers.pathlayer;

// exports
export default layerSlice.reducer;
export const { updateTreeLayer, updatePathLayer } =
layerSlice.actions;
