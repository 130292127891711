import { createApi, fetchBaseQuery  } from "@reduxjs/toolkit/query/react";
import { FeatureCollection } from "geojson";

import { baseUrl } from '../../config/index'
import { Config } from '../../types'

/* 
  The hooks are automatically named based on a standard convention:
  use, the normal prefix for any React hook
  The name of the endpoint, capitalized
  The type of the endpoint, Query or Mutation
*/

export const apiSlice:any = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getConfig: builder.query<Config, void>({
      query: () => "/config/config.json",
    }),
    getResults: builder.query<FeatureCollection, string | null>({
      query: (path) => `/data/results/${path}.json`
    }),
    getTrees: builder.query<FeatureCollection, string | null>({
      query: (path) => `/data/trees/points/${path}.json`
    }),
    getPaths: builder.query<FeatureCollection, string | null>({
      query: (path) => `/data/paths/${path}.json`
    }),
    getBuildings: builder.query<FeatureCollection, string | null>({
      query: (path) => `/data/buildings/${path}.json`
    }),
    getSummary: builder.query<FeatureCollection, string | null>({
      query: (path) => `/data/summary/${path}.json`
    })
  }),
});

// actions
export const { useGetConfigQuery, useGetResultsQuery, useGetTreesQuery, useGetBuildingsQuery, useGetPathsQuery, useGetSummaryQuery } = apiSlice;

// selectors
export const selectConfig = apiSlice.endpoints.getConfig.select();
export const selectResults = apiSlice.endpoints.getResults.select();
