import { useCallback } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import RowContainer from "../common/RowContainer";
import { useAppDispatch } from "../redux/hooks";
import { Values, Value } from "../types";

const colStyle = {
  paddingRight: 0,
  paddingLeft: 0,
};
const labelStyle = {
  fontSize: 14,
  color: "#636363"
}

type Props = {
  action: any;
  id: string;
  name: string;
  values: Values;
  selected: Value | null;
};
export default function ParameterFormSelect({
  action,
  id,
  name,
  values,
  selected,
}: Props) {
  const dispatch = useAppDispatch();
  const handleSelect = useCallback(
    (e: any) => {
      const item = values.find((f) => f.id === e.currentTarget.value);
      if (item) dispatch(action(item));
    },
    [values]
  );

  return (
    <RowContainer>
      <Col style={{ ...colStyle }} xs md={{ span: 4 }}>
        <Form.Label style={{ ...labelStyle }}>{name}</Form.Label>
      </Col>
      <Col style={{ ...colStyle }} xs md={{ span: 8 }}>
        <Form.Select
          onChange={handleSelect}
          value={selected?.id || ""}
          aria-label={name}
        >
          {values &&
            values.map(({ id, name }: Value) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
        </Form.Select>
      </Col>
    </RowContainer>
  );
}
