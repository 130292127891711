import { Value, Values, ParameterKeys, Parameters } from "../../types";
import { TreeSettingsType } from '../features/treesettingsSlice'

// building paths to data
const checkAllValuesExist = (obj: Record<string, any>): boolean => {
  for (const key in obj) {
    if (obj[key] === undefined || obj[key] === null) {
      return false;
    }
  }
  return true;
};
const checkAllParams = (data: Data, keys: ParameterKeys) => {
  const obj1Keys = Object.keys(data).sort();
  const obj2Keys = [...keys].sort();
  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }
  return obj1Keys.every((key, index) => key === obj2Keys[index]);
};
type Data = {
  [key: string]: Value | null;
};
export const validateData = (data: Data | null, keys: ParameterKeys | null) => {
  if (!data || !keys) return false;
  return checkAllValuesExist(data) && checkAllParams(data, keys);
};
export const validatePath = (path: string): boolean =>
  !path.includes("undefined");

export const validateTreeData = (data: Data | null, keys: ParameterKeys | null) => {
  if (!data || !keys) return false;
  const ignoreParams = ['timeofday', 'type']
  const filteredParams = keys.filter(f=>!ignoreParams.includes(f))
  return validateData(data, filteredParams)
}

// building dropdown params
export const filterValues = (values: Values, filter: Value) => {
  return values.filter((f) => {
    if("type" in f) {
      return f.type && f.type.includes(filter.value)
    } else {
      f
    }
  });
};
export const shouldFilter = (item: Parameters, selected: TreeSettingsType): Value | null => {
  if(item?.filter) {
    return item?.filter in selected && selected[item.filter] 
  } else {
    return null
  }
};