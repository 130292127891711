import { useMemo } from "react";
import { VictoryChart, VictoryBar, VictoryLabel, VictoryAxis } from "victory";

import { useAppSelector } from "../../../redux/hooks";
import { summaryPathSelector } from "../../../redux/selectors/pathSelector";
import { selectAnalysisType } from "../../../redux/features/analysisSlice";
import { useGetSummaryQuery } from "../../../redux/features/apiSlice";

import { createChartConfig } from "./utils";

export default function PercentageChart() {
  const filePath = useAppSelector(summaryPathSelector);
  const analysisType = useAppSelector(selectAnalysisType);
  const { data } = useGetSummaryQuery(filePath, {skip:!filePath});

  const chartConfig = useMemo(() => {
    if (!data || !analysisType) return null;
    return createChartConfig(data, analysisType)
  }, [data, analysisType]);

  if (!chartConfig) return null;
  return (
    <VictoryChart
      height={chartConfig.height}
    >
      <VictoryAxis
        dependentAxis
        label={"% of Footpath"}
        tickFormat={(x) => ""}
        style={{ axis: { stroke: "none" }, axisLabel: { fontSize: 22 } }}
      />
      <VictoryBar
        barWidth={40}
        horizontal
        style={{
          data: {
            fill: ({ datum }) => datum.fill,
            stroke: "black",
            strokeWidth: 1,
          },
          labels: { fontSize: 24 },
        }}
        labels={({ datum }) => datum.y.toFixed(0)}
        labelComponent={<VictoryLabel />}
        data={chartConfig.data}
      />
      <VictoryAxis
        label={chartConfig.label}
        style={{
          axis: { stroke: "none" },
          axisLabel: { fontSize: 22 },
          tickLabels: { fontSize: 20, textAnchor: "middle" },
        }}
      />
    </VictoryChart>
  );
}
