import Container from "react-bootstrap/Container";

import TimeSlider from "./TimeSlider";
import AnalysisType from './AnalysisType'
import { useAppSelector } from "../../../redux/hooks";
import {
  selectAnalysis,
  updateTimeOfDay,
  updateAnalysisType,
} from "../../../redux/features/analysisSlice";
import { selectAnalysisParams } from "../../../redux/features/configSlice";

const actionLookup: any = {
  timeofday: updateTimeOfDay,
  analysistype: updateAnalysisType,
};
const componentLookup: any = {
  timeofday: TimeSlider,
  analysistype: AnalysisType
}

export default function AnalysisDropdown() {
  const analysisParams = useAppSelector(selectAnalysisParams);
  const selectedParams = useAppSelector(selectAnalysis);
  return (
    <Container>
      {analysisParams &&
        selectedParams &&
        Object.entries(analysisParams).map(([key, item]) => {
          if (item) {
            const selected = selectedParams[key];
            const { id, name, values } = item
            const Component = componentLookup[item.id]
            return (
              <Component
                key={item.id}
                action={actionLookup[item.id]}
                id={id}
                name={name}
                values={values}
                selected={selected}
              />
            );
          } else {
            return null; // TODO:
          }
        })}
    </Container>
  );
}
