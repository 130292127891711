import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Value } from "../../types";

type Common = Value | null;

export interface TreeSettingsType {
  [key: string]: any;
  treeform: Common;
  spacing: Common;
  orientation: Common;
}
const initialState = {
  treeform: null,
  spacing: null,
  orientation: null,
} as TreeSettingsType;

const treesettingsSlice = createSlice({
  name: "treesettings",
  initialState,
  reducers: {
    updateTreeSettingDefaults: (
      state,
      action: PayloadAction<TreeSettingsType>
    ) => {
      state.treeform = action.payload.treeform;
      state.spacing = action.payload.spacing;
      state.orientation = action.payload.orientation;
    },
    updateTreeform: (state, action: PayloadAction<Value>) => {
      state.treeform = action.payload;
    },
    updateSpacing: (state, action: PayloadAction<Value>) => {
      state.spacing = action.payload;
    },
    updateOrientation: (state, action: PayloadAction<Value>) => {
      state.orientation = action.payload;
    },
  },
});

export const selectTreeSettings = (state: RootState) => state.treesettings;
export const selectTreeform = (state: RootState) => state.treesettings.treeform;
export const selectSpacing = (state: RootState) => state.treesettings.spacing;
export const selectOrientation = (state: RootState) =>
  state.treesettings.orientation;

// exports
export default treesettingsSlice.reducer;
export const {
  updateTreeSettingDefaults,
  updateTreeform,
  updateSpacing,
  updateOrientation,
} = treesettingsSlice.actions;

// thunks
export const updateSpacingFromTreeform = createAsyncThunk<
  Value | null,
  Value,
  { state: RootState }
>("treesettings/updateSpacingFromTreeform", async (selected: Value, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const spacingParams = state.config.data.spacing;
    const currentSpacing = state.treesettings.spacing;

    if(!spacingParams || !selected) return null

    // get list of available values from matching types
    const values = spacingParams.values.filter((f) => { 
      if("type" in f) {
        return f.type && f.type.includes(selected.value)
      } else {
        f
      }
    })
    if(values.length<1) {
      thunkAPI.dispatch(updateTreeform(selected));
      return selected
    }
    // if there is an current spacing value selected don't do anything, otherwise update with smallest available
    if(currentSpacing && values.map(f=>f.id).includes(currentSpacing.id)) {
      thunkAPI.dispatch(updateTreeform(selected));
      return selected
    } else {
      thunkAPI.dispatch(updateTreeform(selected));
      thunkAPI.dispatch(updateSpacing(values[0]));      
    }
    return selected
  } catch(e) {
    console.log("Error updateSpacingFromTreeform :: ", e)
  }
  return selected;
});
