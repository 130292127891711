import { Fragment } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { summaryTextSelector } from "../../../redux/selectors/overviewSelector";

import { Record } from "../../../types";
import { colors } from "../../../styles/common";

const summary: Record = {
  padding: "0.5rem",
  fontSize: 13,
  color: "#fff",
  backgroundColor: "#09549F",
  borderBottom: "4px solid " + colors.lightgreen
};

const textArray = [
  "Currently showing ",
  " analysis results for ",
  " trees every ",
  "m aligned to ",
  ", from ",
  " for "
];
export default function Summary() {
  const data: string[] = useAppSelector(summaryTextSelector);
  return (
    <div style={{ ...summary }}>
      {textArray.map((text: string, index: number) => (
        <Fragment key={`summary-${index}`}>
          {text}
          {text && <strong>{data[index]}</strong>}
        </Fragment>
      ))}
    </div>
  );
}
