import { useRef, useEffect, useCallback } from "react";
import WebScene from "@arcgis/core/WebScene";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import { Polygon } from "@arcgis/core/geometry";
import { FeatureCollection } from "geojson";

import { useAppSelector } from "../redux/hooks";
import { useGetResultsQuery } from "../redux/features/apiSlice";
import { filePathSelector } from "../redux/selectors/pathSelector";
import { getShadeColor, getUvColor } from "../config/symbology";

type ColorProps = {
  [key: string]: (value: number) => any;
};
const typesConfig: ColorProps = {
  Shade: getShadeColor,
  UV: getUvColor,
};

const createGraphic = (
  geometry: any,
  value: string | number,
  analysisType: string
) => {
  const func = typesConfig[analysisType];
  const color = func(+value);
  return new Graphic({
    geometry,
    symbol: {
      // @ts-ignore: fix recommended from https://community.esri.com/t5/arcgis-javascript-maps-sdk-questions/getting-error-while-implementing-the-graphic/td-p/1045434
      type: "simple-fill",
      color: color,
      outline: {
        color: [255, 255, 255],
        width: 1,
      },
    },
  });
};

interface ExtendedFeatureCollection extends FeatureCollection {
  description: string;
}
const createGraphics = (data: ExtendedFeatureCollection): Graphic[] => {
  const graphics: Graphic[] = [];
  const analysisType = data.description;
  data.features.forEach((feature: any) => {
    const { coordinates } = feature.geometry;
    return coordinates.flatMap((coords:any) => {
      const geometry = new Polygon({
        rings: coords,
        spatialReference: { wkid: 4326 },
      });
      const graphic = createGraphic(
        geometry,
        feature.properties.value,
        analysisType
      );
      graphics.push(graphic);
    });
  });
  return graphics;
};

export default function useResultsLayer() {
  const layerRef = useRef<GraphicsLayer>();
  const filePath: string = useAppSelector(filePathSelector);
  const { data } = useGetResultsQuery(filePath, {skip:!filePath});
  const addLayer: any = useCallback((scene: WebScene) => {
    const layer = new GraphicsLayer();
    scene.add(layer);
    layerRef.current = layer;
  }, []);

  useEffect(() => {
    if (layerRef.current && data) {
      layerRef.current.removeAll();
      const graphics = createGraphics(data);
      layerRef.current.addMany(graphics);
    }
  }, [data, layerRef.current]);

  return addLayer;
}
