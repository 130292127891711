import esriConfig from "@arcgis/core/config.js";
esriConfig.assetsPath = "./assets";
import { ErrorBoundary } from "react-error-boundary";

import Loader from "./components/Loader";
import { ErrorComponent, logError } from "./components/ErrorComponent";
import SceneMapView from "./components/Map";
import SidePanel from "./components/SidePanel";
import Legend from "./components/Legend";

export default function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorComponent} onError={logError}>
      <div className="App">
        <Loader />
        <SidePanel />
        <SceneMapView />
        <Legend />
      </div>
    </ErrorBoundary>
  );
}
