import { createSelector } from "@reduxjs/toolkit";
import { selectSelectedLocation } from "../features/locationSlice";
import {
  selectTreeform,
  selectSpacing,
  selectOrientation,
} from "../features/treesettingsSlice";
import { selectAnalysisType, selectTimeOfDay } from "../features/analysisSlice";

const formatAnalysisType = (text:string | undefined) => {
  if(!text) return undefined
  return text.toUpperCase() === 'UV'? text.replace(/^\w/, (c) => c.toUpperCase()) : text.toLowerCase()
}
export const summaryTextSelector: any = createSelector(
  selectSelectedLocation,
  selectTreeform,
  selectSpacing,
  selectOrientation,
  selectTimeOfDay,
  selectAnalysisType,
  (location, treeform, spacing, orientation, timeofday, analysistype) => {
    if (
      [location, treeform, spacing, orientation, timeofday, analysistype].some(
        (f) => f === null
      )
    )
      return "";
    const analysisTypeValue = formatAnalysisType(analysistype?.name)
    return [
      analysisTypeValue,
      treeform?.name.toLowerCase(),
      spacing?.name.toLowerCase(),
      orientation?.name.toLowerCase(),
      timeofday?.name,
      location?.name
    ]// return `Currently showing ${analysistype?.name.toLowerCase()} analysis results for ${treeform?.name} trees every ${spacing?.name}m aligned to ${orientation?.name}, from ${timeofday?.name}`;
  }
);
