import { useRef, useEffect, useCallback } from "react";
import WebScene from "@arcgis/core/WebScene";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import { FeatureCollection } from "geojson";

import { useAppSelector } from "../redux/hooks";
import { useGetPathsQuery } from "../redux/features/apiSlice";
import { selectPathLayer } from "../redux/features/layerSlice";
import { locationSelector } from "../redux/selectors/pathSelector";
import { createPolyline } from "./utils";

const createGraphic = (geometry: any) => {
  return new Graphic({
    geometry,
    symbol: {
      // @ts-ignore: fix recommended from https://community.esri.com/t5/arcgis-javascript-maps-sdk-questions/getting-error-while-implementing-the-graphic/td-p/1045434
      type: "simple-line",
      color: [0, 0, 0],
      width: 2,
    },
  });
};

const createGraphics = (data: FeatureCollection): Graphic[] => {
  return data.features.map((feature: any) => {
    const { coordinates } = feature.geometry;
    const polyline = createPolyline(coordinates);
    return createGraphic(polyline);
  });
};

export default function usePathsLayer() {
  const layerRef = useRef<GraphicsLayer>();
  const location = useAppSelector(locationSelector);
  const toggleLayer = useAppSelector(selectPathLayer);
  const { data } = useGetPathsQuery(location, {skip:!location});

  const addLayer: any = useCallback((scene: WebScene) => {
    const layer = new GraphicsLayer();
    scene.add(layer);
    layerRef.current = layer;
  }, []);

  useEffect(() => {
    if (layerRef.current && data) {
      layerRef.current.removeAll();
      const graphics = createGraphics(data);
      layerRef.current.addMany(graphics);
    }
  }, [data, layerRef.current]);

  useEffect(() => {
    if(layerRef.current) {
      layerRef.current.visible = toggleLayer
    }
  }, [layerRef.current, toggleLayer])

  return addLayer;
}
