import Container from "react-bootstrap/Container";
import ParameterFormSelect from "../../../common/ParameterFormSelect";
import { useAppSelector } from "../../../redux/hooks";
import {
  selectDropdownLocations,
  updateOrientationFromLocation as updateLocation,
} from "../../../redux/features/locationSlice";

export default function LocationDropdown() {
  const data: any = useAppSelector(selectDropdownLocations);

  return (
    <Container>
      {data && (
        <ParameterFormSelect
          key={`dropdown-${data?.parameters?.id}`}
          action={updateLocation}
          id={data?.parameters?.id}
          name={data?.parameters?.name}
          values={data?.parameters?.values}
          selected={data?.selected}
        />
      )}
    </Container>
  );
}
