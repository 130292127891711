import Button from "react-bootstrap/Button";

const SideBarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path d="M0 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3zm5-1v12h9a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H5zM4 2H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h2V2z" />
    </svg>
  );
};

const LeftArrowIcon = () => {
  return (
    <svg
      className="toggle-arrow rotate"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
    >
      <path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" />
    </svg>
  );
};

type Props = {
  toggle: boolean;
  handleToggle: any;
};
export default function SideToggle({ toggle, handleToggle }: Props) {
  return (
    <div className={`toggle-button ${!toggle ? "off-screen" : ""}`}>
      <Button onClick={handleToggle} variant="info" size="sm">
        {toggle? <LeftArrowIcon /> : <SideBarIcon/>}
      </Button>
    </div>
  );
}
