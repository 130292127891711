import Accordion from "react-bootstrap/Accordion";

import LocationDropdown from "./LocationDropdown";
import TreeSettingsDropdown from "./TreeSettingsDropdown";
import Analysis from "../Analysis";
// import CalculateButton from './CalculateButton'
import PercentageChart from "../PercentageChart";

import { Record } from "../../../types";
const style: Record = {
  flexGrow: 1,
  overflowY: "auto",
};
export default function SidePanel() {
  return (
    <div style={{ ...style }}>
      <Accordion defaultActiveKey="0" alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Scenario Options</Accordion.Header>
          <Accordion.Body>
            <LocationDropdown />
            <TreeSettingsDropdown />
            {/* <CalculateButton/>  */}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Analysis</Accordion.Header>
          <Accordion.Body>
            <Analysis />
            <PercentageChart />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
