import "./index.css";
import { useCallback, useState } from "react";

import LegendToggle from "./LegendToggle";
import LegendItems from "./LegendItems";
import LayerToggle from './LayerToggle'

import { colors } from "../../styles/common";

import { useAppSelector } from "../../redux/hooks";
import { selectAnalysisType } from "../../redux/features/analysisSlice";
import { shadeColors, uvColors } from "../../config/symbology";

// so it goes 60-0
const sortedShade = shadeColors.sort((a, b) => a.value - b.value)

const header = {
  fontSize: 16,
  padding: "0.75rem",
  borderBottom: `1px solid ${colors.grey}`,
};
const subheader = {
  fontSize: 12,
  padding: "0.5rem",
};

export default function Legend() {
  const [toggle, setToggle] = useState(true);
  const handleToggle = useCallback(() => {
    setToggle(!toggle);
  }, [toggle]);
  const selectedParams = useAppSelector(selectAnalysisType);
  return (
    <>
      <LegendToggle toggle={toggle} handleToggle={handleToggle} />
      <div className={`legend-panel ${!toggle ? "off-screen" : ""}`}>
        <div style={{ ...header }}>Layers</div>
        <LayerToggle />
        <div style={{ ...subheader }}>
          {selectedParams?.id === "uv" ? "UV Index" : "Time in Shade"}
        </div>
        {
          <LegendItems
            data={selectedParams?.id === "uv" ? uvColors : sortedShade}
          />
        }
      </div>
    </>
  );
}
