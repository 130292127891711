import { Record } from "../../../types";
import { colors } from "../../../styles/common";

const header: Record = {
  padding: "0.75rem 1rem",
  color: "white",
  fontSize: 22,
  fontWeight: 600,
  backgroundColor: colors.darkblue,
};
export default function Header() {
  return (
    <div style={{ ...header }}>
      Scenario Analysis
    </div>
  );
}
