import { createSelector } from "@reduxjs/toolkit";

import { selectTreeSettingParams } from "../features/configSlice";
import { selectTreeSettings } from "../features/treesettingsSlice";
import { selectSelectedLocation } from '../features/locationSlice'
import { filterValues, shouldFilter } from "./utils";
import { Parameters, Value } from "../../types";

export type Data = {
  selected: Value | null;
  parameters: Parameters;
};
const selectCombinedSelected:any = createSelector(
  selectTreeSettings,
  selectSelectedLocation,
  (selectedTreeSettings, selectedLocation) => {
    if ( !selectedTreeSettings ||!selectedLocation) return null;
    return {...selectedTreeSettings, location:selectedLocation}
  }
)
export const selectFilteredTreeForm: any = createSelector(
  selectTreeSettingParams,
  selectCombinedSelected,
  (treeSettingParams, selectedParams) => {
    if (!treeSettingParams || !selectedParams) return null;
    const data: Data[] = [];
    Object.entries(treeSettingParams).forEach(([key, item]) => {
      if (item) {
        const selected = selectedParams[key];
        const { values, ...rest } = item;
        const byFilter = shouldFilter(item, selectedParams);
        const parameters = byFilter ? filterValues(values, byFilter) : values; // remove spacings that don't correspond to treeform
        data.push({
          selected,
          parameters: { values: parameters, ...rest },
        });
      }
    });
    return data;
  }
);
