import { LegendColor } from "../../config/symbology";

const item = {
  display: "flex",
  //   padding: "0.25rem 0.5rem",
  marginBottom: "0.5rem",
  fontSize: 11,
};

type Props = {
  data: LegendColor[];
};
export default function LegendItems({ data }: Props) {
  return (
    <div>
      {data.map((f) => {
        return (
          <div key={`legend-${f.label}`} style={{ ...item }}>
            <div style={{ padding:"0.25rem 0.5rem", border:"solid 1px black", backgroundColor: `rgb(${f.color})` }} />
            <div style={{marginLeft:"0.25rem"}}>{f.legend || f.label}</div>            
          </div>
        );
      })}
    </div>
  );
}
