import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { updateLocation } from "../../redux/features/locationSlice";
import { updateAnalysisDefault } from "../../redux/features/analysisSlice";
import { updateTreeSettingDefaults } from "../../redux/features/treesettingsSlice";
import { ConfigData } from "../../redux/features/configSlice";

import { Value } from "../../types";

type Param = { [key: string]: Value };
const pluck = (data: ConfigData): Param | null => {
  const { defaults, ...rest } = data;
  if (!defaults) return null;
  const params: Param = {}
  Object.entries(defaults).forEach(([key, value]) => {
    const param = rest[key].values.find((f: Value) => f.id === value);
    params[key]=param
  });
  return params
};

export default (data: ConfigData | null) => {
  const [defaultDone, setDefaultDone] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data && !defaultDone) {
      const paramObj = pluck(data);
      if (paramObj) {
        const {
          location,
          treeform,
          spacing,
          orientation,
          analysistype,
          timeofday,
        } = paramObj;
        dispatch(updateLocation(location));
        dispatch(updateAnalysisDefault({ analysistype, timeofday }));
        dispatch(updateTreeSettingDefaults({ treeform, spacing, orientation }));
        setDefaultDone(true);
      }
    }
  }, [data]);

  return defaultDone;
};
