import { createSelector } from "@reduxjs/toolkit";

import { selectAnalysisType, selectTimeOfDay } from "../features/analysisSlice";
import { selectTreeform, selectSpacing, selectOrientation} from '../features/treesettingsSlice'
import { selectSelectedLocation } from "../features/locationSlice";
import { selectParameterKeys } from "../features/configSlice";

import { validateData, validatePath, validateTreeData } from "./utils";

export const filePathSelector: any = createSelector(
  selectParameterKeys,
  selectSelectedLocation,
  selectTreeform,
  selectSpacing,
  selectOrientation,
  selectTimeOfDay,
  selectAnalysisType,
  (parameterKeys, location, treeform, spacing, orientation, timeofday, type) => {
    try {
      const data = {
        location,
        treeform,
        spacing,
        orientation,
        timeofday,
        type
      }
      if (!validateData(data, parameterKeys)) return null;
      const path = `${location?.id}/${location?.value}_${treeform?.value}_${spacing?.value}_${orientation?.value}_${timeofday?.value}_${type?.value}`
      return validatePath(path) ? path : null;
    } catch (e) {
      console.log("Error filePathSelector :: ", e);
      return null;
    }
  }
);
// TODO: update to build path for 
export const treePathSelector: any = createSelector(
  selectParameterKeys,
  selectSelectedLocation,
  selectTreeform,
  selectSpacing,
  selectOrientation,
  (parameterKeys, location, treeform, spacing, orientation) => {
    try {
      const data = {
        location,
        treeform,
        spacing,
        orientation
      }
      if (!validateTreeData(data, parameterKeys)) return null;
      const path = `${location?.id}/${location?.value}_${treeform?.value}_${spacing?.value}_${orientation?.value}`
      return validatePath(path) ? path : null;
    } catch (e) {
      console.log("Error treePathSelector :: ", e);
      return null;
    }
  }
)
export const locationSelector: any = createSelector(
  selectSelectedLocation,
  (location) => {
    if(!location) return null
    return location.id
  }
)
// TODO: update to build path for 
export const summaryPathSelector: any = createSelector(
  filePathSelector,
  (filePath) => {
    if(!filePath) return null
    return `${filePath}_Percentages`
  }
)