import { useEffect, useRef, useState, memo } from "react";
import WebScene from "@arcgis/core/WebScene";
import SceneView from "@arcgis/core/views/SceneView";
import BasemapToggle from "@arcgis/core/widgets/BasemapToggle";

import useResultsLayer from "../../esri/useResultsLayer";
import useBuildingsLayer from "../../esri/useBuildingsLayer";
import usePathsLayer from "../../esri/usePathsLayer";
import useTreesLayer from "../../esri/useTreesLayer";
import useCamera from "../../esri/useCamera";
import useShadows from "../../esri/useShadows";
import { tempCamera, logLocation } from "./temp";

function Map() {
  const mapRef = useRef<HTMLDivElement>(null);
  const [view, setView] = useState<SceneView | null>(null);
  const [scene, setScene] = useState<WebScene | null>(null);

  const [camera, goToSite] = useCamera(view);
  const shadows = useShadows(view);
  const addResultsLayer = useResultsLayer();
  const addBuildingsLayer = useBuildingsLayer();
  const addPathLayer = usePathsLayer();
  const addTreesLayer = useTreesLayer();

  useEffect(() => {
    if (mapRef.current) {
      const scene = new WebScene({
        basemap: "topo-vector",
        // ground: 'world-elevation',
      });
      const view = new SceneView({
        container: mapRef.current,
        map: scene,
        environment: {
          lighting: {
            type: "sun",
            directShadowsEnabled: true,
            ambientOcclusionEnabled: true,
            date: "Dec 21, 08:00:00 GMT+10:00", // TODO: should make it dynamic but requires setting up after config load
          },
        },
        camera: tempCamera,
      });

      view.when(() => {
        // set state
        setView(view);
        setScene(scene);
        const basemapToggle = new BasemapToggle({
          view: view,
          nextBasemap: "hybrid"
        });
        // set default map ui
        view.ui.move(["zoom", "navigation-toggle", "compass"], "bottom-right");
        view.ui.add(basemapToggle, {
          position: "bottom-right"
        });
        // add layers
        addResultsLayer(scene);
        addBuildingsLayer(scene);
        addPathLayer(scene);
        addTreesLayer(scene);
      });
      // view.watch("camera", logLocation) // use to get current view for setting default locations
    }
  }, []);

  return <div className="mapDiv" ref={mapRef} />;
}
export default memo(Map);
