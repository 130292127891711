import { ReactNode } from "react";
import Row from "react-bootstrap/Row";

const style: any = {
  paddingBottom: 5,
  alignItems: "center"
};
type Props = { children: ReactNode };
export default function RowContainer({ children }: Props) {
  return <Row style={{ ...style }}>{children}</Row>;
}
