import Container from "./Container";
import TopContainer from './TopContainer'
import ContentContainer from './ContentContainer'

export default function SidePanel() {
  return (
    <Container>
      <TopContainer />
      <ContentContainer/>      
    </Container>
  );
}
