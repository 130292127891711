import { createSlice, createSelector, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { selectCameras, selectLocation as selectDefaultLocation } from "../features/configSlice";
import { updateOrientation } from "./treesettingsSlice";

import { Cameras, Value } from "../../types";

type Location = Value | null;
interface LocationState {
  [key: string]: any;
  selectedLocation: Location;
}
const initialState = {
  selectedLocation: null,
} as LocationState;

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    updateLocation: (state, action: PayloadAction<Location>) => {
      state.selectedLocation = action.payload;
    },
  },
});
//selectors
export const selectLocation = (state: RootState) => state.location;
export const selectSelectedLocation = (state: RootState) => state.location.selectedLocation;
export const selectCamera = createSelector(
  selectLocation,
  selectCameras,
  (
    location: LocationState,
    cameras: Cameras | null,
  ) => {
    if (!location || !cameras) return null;
    const id = location?.selectedLocation?.id
    return id? cameras[id] : null
  }
);
export const selectDropdownLocations: any = createSelector(
  selectDefaultLocation,
  selectSelectedLocation,
  (locations, selectedLocation) => {
    if (!locations || !selectedLocation) return null;
    return {
      selected: selectedLocation,
      parameters: locations
    }
  }
)
// exports
export default locationSlice.reducer;
export const { updateLocation } = locationSlice.actions;

// thunks
export const updateOrientationFromLocation = createAsyncThunk<
  Value | null,
  Value,
  { state: RootState }
>("treesettings/updateOrientationFromLocation", async (selected: Value, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const orientationParams = state.config.data.orientation;
    const currentOrientation = state.treesettings.orientation;

    if(!orientationParams || !selected) return null

    // get list of available values from matching types
    const values = orientationParams.values.filter((f) => { 
      if("type" in f) {
        return f.type && f.type.includes(selected.value)
      } else {
        f
      }
    })
    if(values.length<1) {
      thunkAPI.dispatch(updateLocation(selected));
      return selected
    }
    // if there is an current spacing value selected don't do anything, otherwise update with smallest available
    if(currentOrientation && values.map(f=>f.id).includes(currentOrientation.id)) {
      thunkAPI.dispatch(updateLocation(selected));
      return selected
    } else {
      thunkAPI.dispatch(updateLocation(selected));
      thunkAPI.dispatch(updateOrientation(values[0]));      
    }
    return selected

  } catch(e) {
    console.log("Error updateOrientationFromLocation :: ", e)
  }
  return selected;
});