import { useRef, useEffect, useCallback } from "react";
import WebScene from "@arcgis/core/WebScene";

import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import { FeatureCollection } from "geojson";

import { useGetBuildingsQuery } from "../redux/features/apiSlice";
import { createPolygon } from './utils'

const createGraphic = ({ polygon, size }: any) => {
  return new Graphic({
    geometry: polygon,
    symbol: {
      // @ts-ignore: fix recommended from https://community.esri.com/t5/arcgis-javascript-maps-sdk-questions/getting-error-while-implementing-the-graphic/td-p/1045434
      type: "polygon-3d",
      symbolLayers: [
        {
          type: "extrude",
          size, // meters in height
          material: { color: "#d2cac1" },
          edges: {
            type: "solid", //"sketch",
            color: "#635666",
            size: 1,
            // extensionLength: 1
          }
        },
      ],
    },
  });
};

const createGraphics = (data: FeatureCollection): Graphic[] => {
  return data.features.map((feature: any) => {
    const { coordinates } = feature.geometry;
    const { Elevation: size } = feature.properties;
    const polygon = createPolygon(coordinates[0]);
    return createGraphic({ polygon, size });
  });
};

export default function useBuildingsLayer() {
  const layerRef = useRef<GraphicsLayer>();
  const { data } = useGetBuildingsQuery("ipswich"); //TODO: make dynamic or just leave for now if theres only one location

  const addLayer: any = useCallback((scene: WebScene) => {
    const layer = new GraphicsLayer();
    scene.add(layer);
    layerRef.current = layer;
  }, []);

  useEffect(() => {
    if (layerRef.current && data) {
      layerRef.current.removeAll();
      const graphics = createGraphics(data);
      layerRef.current.addMany(graphics);
    }
  }, [data, layerRef.current]);

  return addLayer;
}
