import { useEffect } from "react";
import SceneView from "@arcgis/core/views/SceneView";
import SunLighting from "@arcgis/core/views/3d/environment/SunLighting";

import { useAppSelector } from "../redux/hooks";
import { selectTimeOfDay } from "../redux/features/analysisSlice";

const dateLook: Record<string, string> = {
    8: "08:00:00",
    12: "12:00:00",
    15: "15:00:00",
}

export default function useShadows(view: SceneView | null) {
  const timeofday = useAppSelector(selectTimeOfDay);

  useEffect(() => {
    if(view && view.environment && view.environment.lighting instanceof SunLighting) {
        if(timeofday) {
            view.environment.lighting.date = new Date(`Dec 21, ${dateLook[timeofday.id]} GMT+10:00`);
        }
    }
  }, [timeofday]);
  return timeofday
}
