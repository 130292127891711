import { useRef, useEffect, useCallback, useState } from "react";
import WebScene from "@arcgis/core/WebScene";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import Mesh from "@arcgis/core/geometry/Mesh";
import Point from "@arcgis/core/geometry/Point";
import { Feature, Point as GeojsonPoint } from "geojson";

import { useAppSelector } from "../redux/hooks";
import { useGetTreesQuery } from "../redux/features/apiSlice";
import { selectTreeLayer } from "../redux/features/layerSlice";
import { selectTreeform } from "../redux/features/treesettingsSlice";
import { treePathSelector } from "../redux/selectors/pathSelector";
import { baseUrl } from "../config/index";

// consts
const startLocation = new Point({ x: 0, y: 0, z: 0 });
const treeFolder = `${baseUrl}/data/trees`;
type Paths = { name: string; path: string; scale: number };
const paths: Paths[] = [
  { name: "broad", path: "broad.glb", scale: 1 },
  { name: "columnar", path: "columnar.glb", scale: 1 },
  { name: "irregular", path: "irregular.glb", scale: 1 },
  { name: "palm", path: "palm.glb", scale: 1 },
  { name: "round", path: "round.glb", scale: 1 },
  { name: "weeping", path: "weeping.glb", scale: 1 },
];

const createGraphic = (geometry: Mesh) => {
  return new Graphic({
    geometry,
    symbol: {
      // @ts-ignore: fix recommended from https://community.esri.com/t5/arcgis-javascript-maps-sdk-questions/getting-error-while-implementing-the-graphic/td-p/1045434
      type: "mesh-3d",
      symbolLayers: [
        {
          type: "fill", // autocasts as new FillSymbol3DLayer()
          castShadows: false,
          material: {
            color: [255, 255, 255, 0.7],
            colorMixMode: "replace", // info here https://developers.arcgis.com/javascript/latest/sample-code/layers-scenelayer-color-mix-mode/
          },
        },
      ],
    },
  });
};

const arr2obj = (arr: any): Record<string, Mesh> =>
  arr.reduce(
    (obj: any, item: any) => ({ ...obj, [item.name]: item.model }),
    {}
  );
const loadModel = (location: Point, path: string) => {
  return Mesh.createFromGLTF(location, `${treeFolder}/${path}`);
};
const loadModels = async () => {
  const promises = paths.map(async ({ name, path, scale }) => {
    const model = await loadModel(startLocation, path);
    model.scale(scale, { origin: startLocation });
    return { name, model };
  });
  const models = await Promise.all(promises);
  return arr2obj(models);
};

type Models = {
  [key: string]: Mesh;
};
export default function useTreesLayer() {
  const layerRef = useRef<GraphicsLayer>();
  const modelRef = useRef<Models>({});
  const filePath = useAppSelector(treePathSelector);
  const toggleLayer = useAppSelector(selectTreeLayer);
  const treeform = useAppSelector(selectTreeform);
  const [ready, setReady] = useState<boolean>(false);
  const { data } = useGetTreesQuery(filePath, {skip:!filePath});

  const addLayer: any = useCallback(async (scene: WebScene) => {
    try {
      const layer = new GraphicsLayer();
      scene.add(layer);
      layerRef.current = layer;
      const models = await loadModels();
      modelRef.current = models;
      setReady(true);
      return true;
    } catch (e) {
      console.error("Error fetching tree models :: ", e);
      return false;
    }
  }, []);

  useEffect(() => {
    if (layerRef.current && data && treeform && ready) {
      const geometry = modelRef.current[treeform.id];
      layerRef.current.removeAll();
      const graphics = data.features.map((feature: Feature) => {
        const { coordinates } = feature.geometry as GeojsonPoint;
        const location = new Point({
          x: coordinates[0],
          y: coordinates[1],
        });
        const newGeometry = geometry.clone();
        newGeometry.rotate(0, 0, Math.floor(Math.random() * 181));
        newGeometry.centerAt(location, { origin: startLocation });
        return createGraphic(newGeometry);
      });
      layerRef.current.addMany(graphics);
    }
  }, [data, layerRef.current, modelRef.current, treeform, ready]);

  useEffect(() => {
    if(layerRef.current) {
      layerRef.current.visible = toggleLayer
    }
  }, [layerRef.current, toggleLayer])

  return addLayer;
}
