import Container from "react-bootstrap/Container";

import ParameterFormSelect from "../../../common/ParameterFormSelect";
import { useAppSelector } from "../../../redux/hooks";
import {
  updateSpacingFromTreeform,
  updateSpacing,
  updateOrientation,
} from "../../../redux/features/treesettingsSlice";
import {
  selectFilteredTreeForm,
  Data,
} from "../../../redux/selectors/treeformSelector";

const lookup: any = {
  treeform: updateSpacingFromTreeform,
  spacing: updateSpacing,
  orientation: updateOrientation,
};

export default function TreeSettingDropdown() {
  const data: any = useAppSelector(selectFilteredTreeForm);
  return (
    <Container>
      {data &&
        data.map(({ selected, parameters }: Data) => {
          const { id, name, values } = parameters;
          return (
            <ParameterFormSelect
              key={`dropdown-${id}`}
              action={lookup[id]}
              id={id}
              name={name}
              values={values}
              selected={selected}
            />
          );
        })}
    </Container>
  );
}
