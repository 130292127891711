import {
  shadeColors,
  uvColors,
  Color,
} from "../../../config/symbology";

const arrayToRGB = (colorArray: Color): any => {
  return `rgb(${colorArray.join(", ")})`;
};

// shade
const sortedShade = shadeColors.sort((a, b) => b.value - a.value)
const getShadeData = (obj: any) => {
  return sortedShade.map((item) => {
    const {value, color, label} = item
    const y = obj[value]? obj[value] : 0
    return {
      x: label,
      y: +y,
      fill: arrayToRGB(color)
    }
  })
};

// UV
export const getUvItem = (value: number) => {
  const breakpoint = uvColors.find((bp) => value <= bp.value);
  return breakpoint;
};
const getUvData = (obj: any) => {
  return Object.entries(obj).map((item) => {
    const [key, value]: any = item;
    const uvItem = getUvItem(+key);
    if (!uvItem) throw Error("Uv value not found among breakpoints");
    return {
      x: uvItem.label,
      y: +value,
      fill: arrayToRGB(uvItem.color),
    };
  });
};

const chartTypeConfig: any = {
  Shade: {
    label: "Time in Shade (mins)",
    height: 290,
  },
  UV: {
    label: "UV Index",
    height: 150,
  },
};

export const createChartConfig = (obj: any, analysisType: any) => {
  try {
    const config = chartTypeConfig[analysisType.value];
    const isShade = analysisType.value === "Shade";
    const func = isShade ? getShadeData : getUvData;
    const data = func(obj);
    return {
      ...config,
      data
    };
  } catch (e) {
    console.error("Error create chart config:: ", e);
    return null;
  }
};
