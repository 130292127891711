import { CSSProperties } from "react";
import Alert from "react-bootstrap/Alert";

const container: CSSProperties = {
  position: "fixed",
  height: "100vh",
  width: "100vw",
  margin: "auto",
  backgroundColor: "rgba(255,255,255,0.5)",
  zIndex: 1002,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};


export const logError = async (error: Error, info: { componentStack: string }) => {
  console.error(error);
  console.error(info);
};

const defaultMessage =
  "Apologies, an error has occurred. Try refreshing the page. Your error has been logged and the support team will look into the issue if it persists.";
export const ErrorComponent = () => {
  return (
    <div style={{ ...container }}>
      <Alert style={{ maxWidth: 500 }} variant="danger">
        {defaultMessage}
      </Alert>
    </div>
  );
};
