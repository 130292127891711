import { ChangeEvent } from 'react'
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import RowContainer from "../../../common/RowContainer";
import { useAppDispatch } from "../../../redux/hooks";
import { Values, Value } from "../../../types";
import { useCallback } from "react";

const colStyle = {
  paddingRight: 0,
  paddingLeft: 0,
};
const labelStyle = {
  fontSize: 14,
  color: "#636363"
};

type Props = {
  action: any;
  id: string;
  name: string;
  values: Values;
  selected: Value | null;
};
export default function TimeSlider({
  action,
  id,
  name,
  values,
  selected,
}: Props) {
  const dispatch = useAppDispatch();
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const item = values.find((f) => f.id === event.target.id);
    if (item) dispatch(action(item));
  }, [values]);
  return (
    <RowContainer>
      <Col style={{ ...colStyle }} xs md={{ span: 4 }}>
        <Form.Label style={{ ...labelStyle }}>{name}</Form.Label>
      </Col>
      <Col style={{ ...colStyle }} xs md={{ span: 8 }}>
        {values &&
          values.map(({ id, name }: Value) => (
            <Form.Check
              value={selected?.id || ''}
              onChange={handleChange}
              type={"radio"}
              name="analysis-type-group"
              label={name}
              checked={selected?.id === id}
              inline
              id={id}
            />
          ))}
      </Col>
    </RowContainer>
  );
}
