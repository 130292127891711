import { useEffect } from "react";
// https://redux.js.org/tutorials/typescript-quick-start#define-typed-hooks
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import type {RootState, AppDispatch} from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// own helper for thunks
export const useThunk = (fetchData: any) => {
    const dispatch = useAppDispatch();
  
    const data = useAppSelector((state: any) => state.config.data);
    const isFetching = useAppSelector((state) => state.config.isFetching);
    const isError = useAppSelector((state) => state.config.isError);
  
    useEffect(() => {
      dispatch(fetchData());
    }, []);
  
    return { data, isFetching, isError };
  };
  