import { CSSProperties } from "react";
import Spinner from "react-bootstrap/Spinner";

const container: CSSProperties = {
  position: "fixed",
  height: "100vh",
  width: "100vw",
  margin: "auto",
  backgroundColor: "rgba(255,255,255,0.5)",
  zIndex: 1002,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",    
};
const text = {
  padding: 15,
};

type Props = {
  message?: string;
};
const defaultMessage = "Loading...";

export default function Loading({ message }: Props) {
  return (
    <div style={{ ...container }}>
      <Spinner animation="border" role="dark"></Spinner>
      <div style={{ ...text }}>{message ? message : defaultMessage}</div>
    </div>
  );
}
