export const tempCamera = {
  "position": {
    "x": 152.76086562384737,
    "y": -27.61638472885328,
    "z": 118.8983515361324
  },
  "heading": 352.2028456727365,
  "tilt": 46.7475759902076
};
export const logLocation = (camera: any) => {
  console.log("Position:", camera.position.latitude, camera.position.longitude);
  console.log("Tilt:", camera.tilt);
  console.log("Height:", camera.position.z);
  console.log("Heading: ", camera.heading);
};