import Loading from "../Loading";
import {ErrorComponent} from "../ErrorComponent";

import {useThunk as useFetchData} from "../../redux/hooks"
import { fetchContent } from "../../redux/features/configSlice";
import useTempDefault from './useTempDefault'

export default function Loader() {
  const { data, isFetching, isError } = useFetchData(fetchContent);
  const defaultsReady = useTempDefault(data)
  if (isFetching) {
    return <Loading message={"Loading configuration..."} />;
  } else if (isError) {
    return <ErrorComponent />;
  } else return null;
}
