import Button from "react-bootstrap/Button";

const LayerIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="layer1">
        <path
          d="M 9.9921875 2 A 0.50005 0.50005 0 0 0 9.7675781 2.0566406 L 0.26757812 7.0566406 A 0.50005 0.50005 0 0 0 0.26757812 7.9433594 L 9.7675781 12.943359 A 0.50005 0.50005 0 0 0 10.232422 12.943359 L 19.732422 7.9433594 A 0.50005 0.50005 0 0 0 19.732422 7.0566406 L 10.232422 2.0566406 A 0.50005 0.50005 0 0 0 9.9921875 2 z M 10 3.0664062 L 18.425781 7.5 L 10 11.935547 L 1.5742188 7.5 L 10 3.0664062 z M 0.4921875 9.9960938 A 0.50005 0.50005 0 0 0 0.26757812 10.943359 L 9.7675781 15.943359 A 0.50005 0.50005 0 0 0 10.232422 15.943359 L 19.732422 10.943359 A 0.50058684 0.50058684 0 1 0 19.267578 10.056641 L 10 14.935547 L 0.73242188 10.056641 A 0.50005 0.50005 0 0 0 0.4921875 9.9960938 z M 0.4921875 12.996094 A 0.50005 0.50005 0 0 0 0.26757812 13.943359 L 9.7675781 18.943359 A 0.50005 0.50005 0 0 0 10.232422 18.943359 L 19.732422 13.943359 A 0.50058684 0.50058684 0 1 0 19.267578 13.056641 L 10 17.935547 L 0.73242188 13.056641 A 0.50005 0.50005 0 0 0 0.4921875 12.996094 z "
        />
      </g>
    </svg>
  );
};

const RightArrowIcon = () => {
  return (
    <svg
      className="toggle-arrow"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
    >
      <path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" />
    </svg>
  );
};


type Props = {
  toggle: boolean;
  handleToggle: any;
};
export default function LegendToggle({ toggle, handleToggle }: Props) {
  return (
    <div className={`layer-button ${!toggle ? "off-screen" : ""}`}>
      <Button onClick={handleToggle} variant="info" size="sm">
      {toggle? <RightArrowIcon /> : <LayerIcon/>}
      </Button>
    </div>
  );
}
