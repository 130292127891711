import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";

import { baseUrl } from "../../config/index";
import { RootState } from "../store";
import { Cameras, ParameterKeys, Parameters, Defaults } from "../../types";

export type ConfigData = {
  [key: string]: any;
  defaults: Defaults | null;
  cameras: Cameras | null;
  parameterKeys: ParameterKeys | null;
  location: Parameters | null;
  treeform: Parameters | null;
  spacing: Parameters | null;
  orientation: Parameters | null;
  timeofday: Parameters | null;
  analysistype: Parameters | null;
};
interface ConfigState {
  isFetching: boolean;
  isError: string | null | undefined;
  data: ConfigData;
}

const initialState = {
  isFetching: true,
  isError: null,
  data: {
    defaults: null,
    cameras: null,
    parameterKeys: null,
    location: null,
    treeform: null,
    spacing: null,
    orientation: null,
    timeofday: null,
    analysistype: null,
  },
} as ConfigState;

export const fetchContent = createAsyncThunk("config/fetchConfig", async () => {
  const url = `${baseUrl}/data/config/config.json`;
  const response = await fetch(url);
  return response.json();
});

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchContent.pending, (state) => {
        state.isFetching = true;
        state.isError = null;
      })
      .addCase(fetchContent.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isError = null;
        state.data = action.payload;
      })
      .addCase(fetchContent.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = action.error.message;
      });
  },
});
// selectors
export const selectConfig = (state: RootState) => state.config.data;
export const selectCameras = (state: RootState) => state.config.data.cameras;
export const selectLocation = (state: RootState) => state.config.data.location;
export const selectDefault = (state: RootState) => state.config.data.defaults;
export const selectParameterKeys = (state: RootState) =>
  state.config.data.parameterKeys;
export const selectTreeSettingParams = createSelector(selectConfig, (data:ConfigData) => {
  const { treeform, spacing, orientation } = data;
  return {
    treeform, spacing, orientation
  } 
});
export const selectAnalysisParams = createSelector(selectConfig, (data:ConfigData) => {
  const { timeofday, analysistype } = data;
  return {
    timeofday, analysistype
  } 
});
// exports
export default configSlice.reducer;
