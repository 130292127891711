import { useEffect, useCallback } from "react";
import SceneView from "@arcgis/core/views/SceneView";
import Camera from "@arcgis/core/Camera";

import { useAppSelector } from "../redux/hooks";
import { selectCamera } from "../redux/features/locationSlice";

export default function useCamera(view: SceneView | null) {
  const camera = useAppSelector(selectCamera);

  const goToSite: any = useCallback(
    (cameraProps: Camera) => {
      if (view && cameraProps) {
        view.goTo({ ...cameraProps }, { animate: false }).catch((error) => {
          if (error.name != "AbortError") {
            console.error(error);
          }
        });
      }
    },
    [view]
  );

  useEffect(() => {
    goToSite(camera);
  }, [camera]);

  return [camera, goToSite];
}
