import Header from "./Header";
import Summary from "./Summary";

import { Record } from "../../../types";
const style: Record = {
  flexShrink: 0,
};
export default function TopContainer() {
  return (
    <div style={{ ...style }}>
      <Header />
      <Summary />
    </div>
  );
}
