import Form from "react-bootstrap/Form";

import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import {
  selectLayers,
  updateTreeLayer,
  updatePathLayer,
} from "../../redux/features/layerSlice";

const layerArray = [
  { id: "treelayer", label: "Trees", func: updateTreeLayer },
  { id: "pathlayer", label: "Paths", func: updatePathLayer },
];

const style = {
  paddingTop: "0.5rem",
};
const item = {
  fontSize: 14,
};

export default function LayerToggle() {
  const dispatch = useAppDispatch();
  const layerObj = useAppSelector(selectLayers);

  return (
    <div style={{ ...style }}>
      {layerArray.map(({ id, label, func }) => {
        const checked = layerObj[id];
        return (
          <div key={`layer-${id}`} style={{ ...item }}>
            <Form.Check
              value={id}
              checked={checked}
              onChange={() => dispatch(func(!checked))}
              type="checkbox"
              id={`layer-${id}`}
              label={label}
            />
          </div>
        );
      })}
    </div>
  );
}
